export const environment = {
  production: true,
  application: 'swissisol',
  apiUrl: '', // claim-based, see profileService
  stsUrl: 'https://accounts.swissisol.ch',
  logUrl: 'https://log.viag.ch',
  clientId: 'swissisol',
  enableRemoteLog: true,
  enableConsoleLog: true, // TODO: disable wenn die "gröbsten Bugs behoben sind!"
  version: '3'
};
